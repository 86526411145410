import { useMemo, useState, useEffect } from 'react'
import classNames from 'classnames'
import CATEGORIES from '@/temp-configs/category-constants'

import { handleTag, ProductCTA } from '@saatva-bits/pattern-library.modules.detail-modal'
import { useProductState, useProductData, useAttributeOptions, ProductSelectors } from '@saatva-bits/pattern-library.modules.selection'
import { usePromotionsData } from '@saatva-bits/pattern-library.modules.promotions'
import { useGetDiscounts, applyDiscounts } from '@saatva-bits/pattern-library.modules.promotions'
import { DetailProductTile } from '@saatva-bits/pattern-library.components.detail-product-tile'
import { useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly'

import AddToCartButton from '@/components/ButtonAddToCart'
import DetailModalVariant from '@/components/DetailModalVariant'
import { useFiltersData } from '@/contexts/filters'

import { getSwatches, getArdadImages as getArdadImagesFunction, getSwatchFromSelections } from '@/utils/product'
import { affirmRefresh } from '@/utils/affirm'

import { slots } from '@/config/ardad-configs'

import styles from './ProductTiles.module.scss'

const IMGIX_URL = process.env.NEXT_PUBLIC_IMGIX_URL

const commonImageProps = {
    widths: { mobile: 348 },
    imgixDomain: IMGIX_URL,
    lazyLoad: true,
    /** Override the lazyload offset added by the detail product tile from bit  */
    lazyLoadOffset: null
}

const ProductTile = ({ product, valuesGroups, hideSwatchesImages }) => {
    const [modalOpen, setModalOpen] = useState(false)
    const { filterOptions } = useFiltersData()

    const colorMaterialOptions = useMemo(() => filterOptions.filter(option => option.property === 'colors' || option.property === 'materials'), [filterOptions])
    const isBeddingCategory = product.category.toLowerCase() === CATEGORIES.BEDDING
    const isBundleCategory = product.category.toLowerCase() === CATEGORIES.BUNDLES
    const showAddToCartUI = isBeddingCategory || isBundleCategory
    const { sitewidePromo } = usePromotionsData()
    const { enableBundleDiscounts, bundleDiscountPercent } = sitewidePromo

    // BEGIN PDP Out of Stock Styling test EX-260
    const { isV1: isOutOfStockStylingEnabled } = useExperiment('EXP.OOS_BUYSTACK.EX-260')
    const isExcludedFromOosByCategory = [
        CATEGORIES.FURNITURE,
        CATEGORIES.MATTRESSES,
        CATEGORIES.BUNDLES
    ].includes(product.category.toLowerCase())

    const isOutOfStockStyling = isOutOfStockStylingEnabled && !isExcludedFromOosByCategory
    // END PDP Out of Stock Styling test EX-260

    // TODO: use once the filter works filtering by variants update so it takes sizes into account
    // const priceRange = usePriceRange(product.variants)
    
    const productData = useMemo(() => {
        let lowerPrice = product.lowerPrice
        let upperPrice = product.upperPrice

        // temporarily handling bundle discounts manually. Removing extra value discounts from original min/max.
        if (isBundleCategory && enableBundleDiscounts && bundleDiscountPercent) {
            const bundleDiscount = bundleDiscountPercent / 100
            lowerPrice = product.lowestPrice - (product.lowestPrice * bundleDiscount)
            upperPrice = product.highestPrice - (product.highestPrice * bundleDiscount)
        }

        return {
            ...product,
            ...product.content,
            priceRange: {
                fullPriceMin: product.lowestPrice,
                fullPriceMax: product.highestPrice,
                finalPriceMin: lowerPrice || 0,
                finalPriceMax: upperPrice || 0,
            },
            selectedSizes: valuesGroups.selectedSizes
        }
    }, [enableBundleDiscounts, bundleDiscountPercent, isBundleCategory, product, valuesGroups.selectedSizes])

    const productCode = productData.productCode

    const onAddToCart = () => {
        setModalOpen(false)
    }

    const customOptionProps = {
        'bathSet': {
            toggleProps: {
                modifyOptionLabel: (label) => { return label.replace('Bath', `\nBath`) },
                autoFitColumnLayoutOverride: true
            }
        },
    }

    const attributesWithCustomSetup = ['color', 'fabric', 'scent', 'quantity']
    const hiddenAttributes = ['quantity']

    productData.options.forEach(option => {
        // Create customProps to modify default behavior specific attributes
        if (!attributesWithCustomSetup.includes(option.code)) {
            customOptionProps[option.code] = { displayType: 'toggle' }
            !showAddToCartUI && hiddenAttributes.push(option.code)
        }
        // Create customProps to modify default selectors label based on category
        else if (!isBeddingCategory) {
            customOptionProps[option.code] = {
                toggleProps: {
                    label: option.displayName,
                    isCustomLabel: true
                }
            }
        }
    })

    const addToCartSection = (
        <div className={styles.addToCartSection}>
            {showAddToCartUI &&
                <AddToCartButton
                    productCode={productCode}
                    onAddToCart={onAddToCart}
                    hasQtySelector={true}
                >
                    Add to Cart
                </AddToCartButton>
            }
            <ProductCTA
                productCode={productCode}
                selectionDeps={{ useProductState }}
                shopCta={!showAddToCartUI && productData.shopCta}
                showLearnMoreCta={showAddToCartUI} />
        </div>
    )

    const buystack = (
        <ProductSelectors
            className={styles.detailModalSelectors}
            productCode={productCode}
            customOptionProps={customOptionProps}
            shortDropdowns={true}
            hiddenAttributes={hiddenAttributes}
            isOutOfStockStyling={isOutOfStockStyling} />
    )

    const getImages = getArdadImagesFunction(productData, slots.productTile, commonImageProps, hideSwatchesImages)

    let swatches = getSwatches(product.options, productData.defaultSwatch, productCode.length)

    const swatchOverride = getSwatchFromSelections(colorMaterialOptions, valuesGroups, product.matchingVariants)

    if (swatches && swatchOverride) swatches = { ...swatches, initialSelection: swatchOverride }

    const tag = handleTag(productData.isNew, productData.tag)

    const affirmPrice = product.category.toLowerCase() !== CATEGORIES.BEDDING ? { price: productData.priceRange.finalPriceMin } : null

    const containerClassNames = classNames(styles.productTileContainer, {
        'u-marginBottom--2xl': !swatches && !affirmPrice,
        'u-marginBottom--xs': swatches
    })

    const modalContainerProps = {
        closeButtonClasses: styles.closeButton,
        modalClasses: styles.detailModalContainer
    }

    return <>
        <DetailProductTile
            imageCTA={{ text: 'View Details', onClick: () => setModalOpen(true) }}
            title={{ text: productData.title, href: product.url, titleClassName: styles.title }}
            subtitle={{ text: productData.subtitle, className: styles.subtitle }}
            priceRange={productData.priceRange}
            imageHref={product.url}
            affirm={affirmPrice}
            getImages={getImages}
            swatches={swatches}
            containerClassName={containerClassNames}
            imageBadgeLeft={tag && { text: tag }}
        />
        <DetailModalVariant
            isOpen={modalOpen}
            closeHandler={() => setModalOpen(false)}
            productData={productData}
            buystack={buystack}
            addToCartSection={addToCartSection}
            promotionDeps={{
                useGetDiscounts,
                applyDiscounts
            }}
            selectionDeps={{
                useProductData,
                useProductState,
                useAttributeOptions
            }}
            modalContainerProps={modalContainerProps}
        />
    </>
}

/**
 * @param {object} props
 * @param {any[]} props.products
 * @param {{ selectedSizes: string[]?, selectedColors: string[]? }} props.valuesGroups - Values selected by user that change content shown in page
 * @param {string} props.category - Category of the products
 * @param {Function} props.clearFilters - Function to clear filters
 * @param {boolean} props.hideSwatchesImages - Hide swatches images
 */
export default function ProductTiles({ products, valuesGroups, clearFilters, hideSwatchesImages }) {

    useEffect(() => {
        affirmRefresh(100)
    })

    if (products.length <= 0) {
        return <div className='col col--xs-12 col--sm-8 col--md-6 col--lg-4'>
            <div className={styles.noProductsTile}>
                <h2>We couldn’t find any available products with the filters you selected. Please try clearing your filters to see available options. <button className={styles.clearButton} onClick={clearFilters}>Clear Filters</button></h2>
            </div>
        </div>
    }

    return products.map((product) => (
        <div key={product.productCode} className={'col col--xs-12 col--sm-8 col--md-6 col--lg-4 u-marginBottom--2xl'}>
            <ProductTile
                product={product}
                valuesGroups={valuesGroups}
                hideSwatchesImages={hideSwatchesImages}
            />
        </div>
    ))
}
